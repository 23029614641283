/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import Header from "./header";
import CallToAction from "./cta";
import "./layout.css";

import { makeStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";

const useStyles = makeStyles({
  root: {
    position: "relative",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    "--headerHeight": "80px",
    "@media(max-width: 960px)": {
      "--headerHeight": "50px",
    },
    "@media(max-width: 360px)": {
      fontSize: "0.8em",
    },
  },
});

export default function Layout({
  children,
  fullscreen,
  maxWidth,
  removeCTA,
  removeFooter,
  removeHeader,
  fullWidth,
  mainFullHeight,
}: {
  children: React.ReactNode;
  fullscreen?: boolean;
  maxWidth?: number;
  removeCTA?: boolean;
  removeFooter?: boolean;
  removeHeader?: boolean;
  fullWidth?: boolean;
  mainFullHeight?: boolean;
}) {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  React.useEffect(() => {
    if (fullscreen) {
      document.querySelector("html").classList.add("scrollsnap");
    }
    return () => document.querySelector("html").classList.remove("scrollsnap");
  }, [fullscreen]);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      {!removeHeader && <Header siteTitle="PanoRoom" />}
      <div
        style={{
          position: "relative",
          paddingTop: fullscreen ? "0" : `1.45rem`,
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            margin: `0 auto`,
            maxWidth: maxWidth ?? (fullscreen ? undefined : 960),
            width: fullscreen || fullWidth ? "100%" : undefined,
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <main style={mainFullHeight ? { height: "100%" } : {}}>
            {children}
          </main>
          <div style={{ flexGrow: 1 }} />
        </div>
      </div>
      {!removeCTA && <CallToAction />}

      {!removeFooter && (
        <footer>
          © {new Date().getFullYear()} PanoRoom, Built with
          {` `}
          <a href="https://www.gatsbyjs.org">Gatsby</a>
        </footer>
      )}
    </div>
  );
}
